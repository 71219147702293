<template>
  
  <div class="content">
    <van-button plain hairline  size="mini" to="index" block loading-text="加载中..."  icon="https://img01.yzcdn.cn/vant/user-active.png" type="primary" @click="()=>{console.log('我被点击了');}">URL 跳转</van-button>
    <br>
    <van-cell-group inset title="分组1" :border="false">
      <van-cell required title="单元格" value="内容"  icon="location-o" is-link to="vant"/>
      <van-cell  center title="单元格" value="内容" >
        <template #title>
          <span class="custom-title">单元格</span>
          <van-tag type="primary">标签</van-tag>
        </template>
        <template #right-icon>
          <van-icon name="search" class="search-icon" />
        </template>
     </van-cell>
    </van-cell-group>
    <br>
    <div class="title">asdf</div>
  </div>

</template>

<script>
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US', enUS);

export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
:root{
  --primary-color: red;
}
:root:root{
  --primary-color: blue;
}
</style>
<style lang="scss" scoped>

.title{
  background-color: #AAAAAA;
  color: var(--primary-color);
}
</style>
